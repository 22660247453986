import React from "react";
import apptiveLogo from "../assets/apptive-logo.png";
import "../scss/main.scss";

const Header = () => {
  return (
    <div className="header-container">
      <img
        className="header-container__apptive-logo"
        src={apptiveLogo}
        alt="logo"
      />
      <span>Components</span>
    </div>
  );
};

export default Header;
