import "../scss/components/input.scss";

const Input = ({ placeholder, onChange, icon, backgroundColor }) => {
  return (
    <div className="input" style={{ backgroundColor }}>
      {icon}
      <input type="text" placeholder={placeholder} onChange={onChange} />
    </div>
  );
};

export default Input;
