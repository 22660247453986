import "../scss/main.scss";
import React, { Component } from "react";
import PartnerItem from "../components/PartnerItem";
import httpClient from "../api/httpClient";
import { getQueryVariable } from "../helpers/urlQuery";
import { colorModifier } from "../helpers/colorModifier";
import Marker from "../components/Marker";
import GoogleMapReact from "google-map-react";
import mapStyle from "../helpers/mapStyle";
import { FiCheckCircle, FiLink, FiClock } from "react-icons/fi";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoCalendarOutline } from "react-icons/io5";
import ImageSlider from "../components/ImageSlider";
import ApptiveLogo from "../assets/apptive-logo.png";
class PartnerDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      partner: null,
      brandingConfig: null,
      images: [],
      isImagePreviewOpen: false,
      isLoading: false,
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true,
    });
    let companyId = getQueryVariable("company_id");
    let partnerId = getQueryVariable("partner_id");
    if (!companyId) {
      window.location.replace("/error?message=company-id-not-provided");
    }
    if (!partnerId) {
      window.location.replace("/error?message=partner-id-not-provided");
    }

    let brandingConfig = await httpClient
      .get("/company?company_id=" + companyId)
      .catch((error) => {
        window.location.replace("/error?message=" + error.response.status);
      });

    let partner = await httpClient
      .get(`/partner/details?partner_id=${partnerId}&company_id=${companyId}`)
      .catch((error) => {
        window.location.replace("/error?message=" + error.response.status);
      });

    this.setState({
      partner: partner?.data?.response,
      brandingConfig: brandingConfig?.data?.response?.brand,
      isLoading: false,
      companyName: brandingConfig?.data?.response?.name,
    });
  };

  render() {
    let { brandingConfig, partner, isLoading } = this.state;
    return (
      <div
        className="main-container"
        style={{ backgroundColor: brandingConfig?.primary_color }}
      >
        {isLoading ? (
          <div className="loader-container">
            <div
              style={{
                position: "relative",
              }}
            >
              <div className="loader"></div>
              <img
                src={ApptiveLogo}
                height={40}
                width={100}
                style={{
                  objectFit: "contain",
                  position: "absolute",
                  top: 45,
                  bottom: 0,
                  left: 15,
                  right: 15,
                }}
              />
            </div>
          </div>
        ) : (
          <>
            {/* partner details container */}
            <div
              className="partner-details-container"
              style={{ backgroundColor: brandingConfig?.primary_color }}
            >
              <div className="partner-details-container__partner-info">
                <PartnerItem
                  backgroundColor={colorModifier(
                    brandingConfig?.primary_color,
                    50
                  )}
                  primaryColor={brandingConfig?.primary_color}
                  secondaryColor={brandingConfig?.secondary_color}
                  partner={partner}
                  hideMoreInfo={true}
                  showMapIcon={true}
                />
                <div className="partner-details-container__partner-info__description">
                  {partner?.infos.map((info, key) => {
                    return (
                      <span
                        key={key}
                        style={{ color: brandingConfig?.font_color }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: info?.description,
                          }}
                        />
                      </span>
                    );
                  })}
                </div>
              </div>

              <div className="partner-details-container__slider">
                {partner?.infos.length > 0 && (
                  <div className="roles-opening-hours-container__roles-container">
                    <span
                      className="title"
                      style={{
                        color: brandingConfig?.secondary_color,
                      }}
                    >
                      Besuchsregelung
                    </span>
                    <div
                      className="roles-opening-hours-container__roles-container__role-item"
                      style={{ paddingTop: 14 }}
                    >
                      {partner?.infos.map((info, key) => {
                        return (
                          <span
                            key={key}
                            style={{
                              color: brandingConfig?.font_color,
                            }}
                          >
                            <div
                              dangerouslySetInnerHTML={{
                                __html: info?.rules,
                              }}
                            />
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* info container */}
            {partner?.tags.length > 0 && (
              <div
                className="info-container"
                style={{ backgroundColor: brandingConfig?.secondary_color }}
              >
                {partner?.tags.length > 0 && (
                  <div className="info-container__offers-container">
                    <p
                      className="title"
                      style={{ color: brandingConfig?.primary_color }}
                    >
                      Angebot
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      {partner?.tags.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="info-container__offers-container__offer-item"
                          >
                            <FiCheckCircle
                              color={brandingConfig?.primary_color}
                            />
                            <span
                              className="info-container__offers-container__offer-item__text"
                              style={{ color: brandingConfig?.primary_color }}
                            >
                              {item?.tag}
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {partner?.links.length > 0 && (
                  <div className="info-container__links-container">
                    <p
                      className="title"
                      style={{ color: brandingConfig?.primary_color }}
                    >
                      Links
                    </p>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                      }}
                    >
                      {partner?.links.map((item, key) => {
                        return (
                          <div
                            key={key}
                            className="info-container__links-container__link-item"
                          >
                            {item.icon.includes("calendar") ? (
                              <IoCalendarOutline
                                color={brandingConfig?.primary_color}
                              />
                            ) : (
                              <FiLink color={brandingConfig?.primary_color} />
                            )}
                            <span>
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={item?.link}
                                className="info-container__links-container__link-item__text"
                                style={{ color: brandingConfig?.primary_color }}
                              >
                                {item?.title}
                              </a>
                            </span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
            {/* roles and opening hours container */}

            <div
              className="roles-opening-hours-container"
              style={{
                backgroundColor: brandingConfig?.primary_color,
              }}
            >
              {partner?.opening_hours.length > 0 && (
                <div className="roles-opening-hours-container__opening-hours-container">
                  <p
                    className="title"
                    style={{ color: brandingConfig?.secondary_color }}
                  >
                    Öffnungszeiten
                  </p>
                  {partner?.opening_hours.map((item, key) => {
                    let day = Object.keys(item)[0];
                    return (
                      <div
                        key={key}
                        className="roles-opening-hours-container__opening-hours-container__opening-hours-item"
                      >
                        <div
                          style={{
                            flexDirection: "row",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <span
                            style={{
                              color: brandingConfig?.font_color,
                              paddingRight: 20,
                              flex: 1.2,
                            }}
                          >
                            {day}
                          </span>
                          <div className="roles-opening-hours-container__opening-hours-container__opening-hours-item__clock-container">
                            <FiClock color={brandingConfig?.secondary_color} />
                            <span
                              className="roles-opening-hours-container__opening-hours-container__opening-hours-item__text"
                              style={{
                                color: brandingConfig?.secondary_color,
                              }}
                            >
                              {item[day].start} - {item[day].end}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <span style={{ color: brandingConfig?.secondary_color }}>
                    *Alle aufgeführten Zeiten gelten für{" "}
                    {this.state.companyName}-Member.
                  </span>
                </div>
              )}
              <div
                className="partner-details-container__slider"
                style={{
                  justifyContent: "center",
                }}
              >
                {partner?.images.length > 0 && (
                  <ImageSlider
                    slides={partner?.images}
                    brandingColors={brandingConfig}
                  />
                )}
              </div>
            </div>
            <div
              className="map-details-container"
              style={{ backgroundColor: brandingConfig?.secondary_color }}
            >
              {partner && (
                <GoogleMapReact
                  center={{
                    lat: parseFloat(partner?.latitude),
                    lng: parseFloat(partner?.longitude),
                  }}
                  bootstrapURLKeys={{
                    key: "AIzaSyCiBbg7g7lY7P1hLttTu38_6c_zwXyScps",
                  }}
                  defaultZoom={13}
                  options={{
                    styles: mapStyle(
                      brandingConfig?.primary_color,
                      colorModifier(brandingConfig?.primary_color, 50)
                    ),
                  }}
                >
                  <Marker
                    lat={partner?.latitude}
                    lng={partner?.longitude}
                    backgroundColor={brandingConfig?.secondary_color}
                    className={"selected-marker"}
                    animation={2}
                    image={`data:image/jpg;charset=utf-8;base64,${partner?.logo}`}
                  />
                </GoogleMapReact>
              )}
            </div>
          </>
        )}
        {!isLoading && (
          <div
            style={{
              position: "absolute",
              right: 20,
              top: 20,
              cursor: "pointer",
            }}
            onClick={() => window.close()}
          >
            <RiCloseCircleLine
              color={brandingConfig?.secondary_color}
              style={{
                backgroundColor: brandingConfig?.primary_color,
                borderRadius: "50%",
              }}
              size={40}
            />
          </div>
        )}
      </div>
    );
  }
}

export default PartnerDetails;
