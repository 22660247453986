import axios from "axios";
import { BASE_URL } from "../utils/urls";
import * as AxiosLogger from "axios-logger";

const httpClient = axios.create({
  baseURL: BASE_URL,
  timeout: 15000,
  withCredentials: false,
});
httpClient.interceptors.request.use(
  AxiosLogger.requestLogger,
  AxiosLogger.errorLogger
);
httpClient.interceptors.response.use(
  AxiosLogger.responseLogger,
  AxiosLogger.errorLogger
);
export default httpClient;
