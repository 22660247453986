import { Component } from "react";
import Header from "../components/Header";
import { getQueryVariable } from "../helpers/urlQuery";
import "../scss/main.scss";

class Error extends Component {
  state = {
    errorMessage: "",
  };
  componentDidMount = () => {
    let errorMessage = getQueryVariable("message");
    switch (errorMessage) {
      case "company-id-not-provided":
        this.setState({
          errorMessage: "Company id is not provided",
        });
        break;
      case "partner-id-not-provided":
        this.setState({
          errorMessage: "Partner id is not provided",
        });
        break;
      case "404":
        this.setState({
          errorMessage: "Company is not found",
        });
        break;
      default:
        this.setState({
          errorMessage: "Something went wrong",
        });
        break;
    }
  };

  render() {
    let { errorMessage } = this.state;
    return (
      <>
        <Header />
        <div className="error-container">
          <h1 className="error-container__title">{errorMessage}</h1>
        </div>
      </>
    );
  }
}

export default Error;
