import "../scss/main.scss";
import React, { Component } from "react";
import Input from "../components/Input";
import PartnerItem from "../components/PartnerItem";
import GoogleMapReact from "google-map-react";
import mapStyle from "../helpers/mapStyle";
import Marker from "../components/Marker";
import { FaSearch, FaSearchLocation } from "react-icons/fa";
import { getQueryVariable } from "../helpers/urlQuery";
import httpClient from "../api/httpClient";
import { colorModifier } from "../helpers/colorModifier";

class Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showClass: "hide-list",
      partners: [],
      center: {
        lat: 0,
        lng: 0,
      },
      defaultZoom: 11,
      clickedPartnerIndex: -1,
      brandingConfig: null,
      mapHeight: false,
      locationText: "",
      searchText: "",
      companyId: 1,
    };
  }

  componentDidMount = async () => {
    // get company id
    let companyId = getQueryVariable("company_id");
    // get map component height
    let height = getQueryVariable("height");
    if (!companyId) {
      window.location.replace("/error?message=company-id-not-provided");
    }
    // get the brand colors from the api
    let brandingConfig = await httpClient
      .get("/company?company_id=" + companyId)
      .catch((error) => {
        window.location.replace("/error?message=" + error.response.status);
      });

    // get the partners depending on the company id
    const partners = await httpClient
      .get("/partners/" + companyId)
      .catch((error) => {
        window.location.replace("/error?message=" + error);
      });

    this.setState({
      partners: partners?.data?.response,
      brandingConfig: brandingConfig?.data?.response?.brand,
      center: {
        lat: parseFloat(partners?.data?.response[0]?.latitude),
        lng: parseFloat(partners?.data?.response[0]?.longitude),
      },
      mapHeight: parseInt(height),
      companyId: companyId,
    });
  };
  render() {
    let {
      showClass,
      partners,
      center,
      clickedPartnerIndex,
      brandingConfig,
      mapHeight,
      searchText,
      locationText,
      defaultZoom,
      companyId,
    } = this.state;

    let filteredPartners = partners?.filter((item) => {
      return (
        item.name.toLowerCase().indexOf(searchText.toLowerCase()) !== -1 &&
        (item.city.toLowerCase().indexOf(locationText.toLowerCase()) !== -1 ||
          item.adress.toLowerCase().indexOf(locationText.toLowerCase()) !==
            -1 ||
          item.plz.toLowerCase().indexOf(locationText.toLowerCase()) !== -1)
      );
    });

    return (
      <div className="map-container" style={{ height: mapHeight && mapHeight }}>
        <div
          {...{
            className: "map-container__list-container " + showClass,
          }}
          style={{ backgroundColor: brandingConfig?.primary_color }}
        >
          <div className="map-container__list-container__drag-line-container">
            <div
              className="map-container__list-container__drag-line-container__drag-line"
              id="drag-line"
              onClick={() => {
                this.setState({
                  showClass:
                    showClass === "show-list" ? "hide-list" : "show-list",
                });
              }}
            />
          </div>
          <div className="map-container__list-container__input-container">
            <Input
              backgroundColor={colorModifier(brandingConfig?.primary_color, 50)}
              placeholder="Suchen"
              onChange={(searchText) => {
                this.setState({ searchText: searchText.target.value });
              }}
              icon={<FaSearch color="white" className="input__icon" />}
            />
            <Input
              backgroundColor={colorModifier(brandingConfig?.primary_color, 50)}
              placeholder="Standort"
              onChange={(locationText) => {
                this.setState({ locationText: locationText.target.value });
              }}
              icon={<FaSearchLocation color="white" className="input__icon" />}
            />
          </div>
          <div
            {...{
              className:
                "map-container__list-container__list " + showClass + "__list",
            }}
          >
            {filteredPartners.map((partner, index) => {
              return (
                <PartnerItem
                  companyId={companyId}
                  id={index}
                  backgroundColor={colorModifier(
                    brandingConfig?.primary_color,
                    50
                  )}
                  primaryColor={brandingConfig?.primary_color}
                  secondaryColor={brandingConfig?.secondary_color}
                  className={clickedPartnerIndex === index && "selected-item"}
                  key={index}
                  partner={partner}
                  onClick={() => {
                    this.setState({
                      center: {
                        lat: parseFloat(partner?.latitude),
                        lng: parseFloat(partner?.longitude),
                      },
                      clickedPartnerIndex: index,
                      showClass: "hide-list",
                    });
                  }}
                />
              );
            })}
          </div>
        </div>
        <div className="map-container__map">
          <GoogleMapReact
            center={center}
            bootstrapURLKeys={{
              key: "AIzaSyCiBbg7g7lY7P1hLttTu38_6c_zwXyScps",
            }}
            defaultZoom={defaultZoom}
            options={{
              styles: mapStyle(
                brandingConfig?.primary_color,
                colorModifier(brandingConfig?.primary_color, 50)
              ),
            }}
          >
            {filteredPartners.map((partner, index) => {
              return (
                <Marker
                  lat={partner?.latitude}
                  lng={partner?.longitude}
                  key={index}
                  backgroundColor={brandingConfig?.secondary_color}
                  className={clickedPartnerIndex === index && "selected-marker"}
                  animation={2}
                  onClick={() => {
                    this.setState({
                      center: {
                        lat: parseFloat(partner?.latitude),
                        lng: parseFloat(partner?.longitude),
                      },
                      defaultZoom: 20,
                      clickedPartnerIndex: index,
                    });
                    let clickedElement = document.getElementById(index);
                    clickedElement.scrollIntoView({
                      block: "center",
                      behavior: "smooth",
                    });
                  }}
                  image={`data:image/jpg;charset=utf-8;base64,${partner?.logo}`}
                />
              );
            })}
          </GoogleMapReact>
        </div>
      </div>
    );
  }
}

export default Map;
