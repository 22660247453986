import React, { Component } from "react";
import Header from "../components/Header";
import "../scss/main.scss";

class NoMatch extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="no-match-container">
          <span className="no-match-container__title">
            {window.location.pathname} <span className="or">|</span> 404 - Not
            found
          </span>
        </div>
      </>
    );
  }
}

export default NoMatch;
