import React, { Component } from "react";
import Header from "../components/Header";
import { Link } from "react-router-dom";

import "../scss/main.scss";

class Home extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="home-container">
          <div className="home-container__list-of-component">
            <ol>
              <li>
                <Link to="/map?company_id=1">Map</Link>
                <Link to="/map?company_id=1">
                  <span className="url-style">
                    https://components.apptive.ch/map<span>?company_id=1</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
                <Link to="/login">
                  <span className="url-style">
                    https://components.apptive.ch/login
                    <span>?company_id=1</span>
                  </span>
                </Link>
              </li>
              <li>
                <Link to="/register">Register</Link>
                <Link to="/register">
                  <span className="url-style">
                    https://components.apptive.ch/register
                    <span>?company_id=1</span>
                  </span>
                </Link>
              </li>
            </ol>
          </div>
        </div>
      </>
    );
  }
}

export default Home;
