import React, { useState } from "react";
import { MdOutlineArrowForwardIos, MdArrowBackIosNew } from "react-icons/md";

import "../scss/main.scss";

const ImageSlider = ({ slides, brandingColors }) => {
  const [current, setCurrent] = useState(0);
  const length = slides?.length;

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  if (!Array.isArray(slides) || slides.length <= 0) {
    return null;
  }
  return (
    <section className="slider">
      {slides.map((slide, index) => {
        return (
          <div
            className={index === current ? "slide active" : "slide"}
            key={index}
          >
            {index === current && (
              <img
                src={`data:image/jpg;charset=utf-8;base64,${slide?.picture}`}
                alt={index}
                className="image"
              />
            )}
          </div>
        );
      })}
      <MdArrowBackIosNew
        className="left-arrow"
        onClick={prevSlide}
        color={brandingColors?.font_color}
        style={{
          backgroundColor: brandingColors?.primary_color,
          color: brandingColors?.secondary_color,
        }}
      />
      <MdOutlineArrowForwardIos
        className="right-arrow"
        onClick={nextSlide}
        color={brandingColors?.font_color}
        style={{
          backgroundColor: brandingColors?.primary_color,
          color: brandingColors?.secondary_color,
        }}
      />
    </section>
  );
};

export default ImageSlider;
