import "../scss/main.scss";

const Marker = ({ image, className, onClick, backgroundColor }) => {
  return (
    <div
      {...{ className: "marker-container " + className }}
      style={{ backgroundColor }}
      onClick={onClick}
    >
      <img
        src={image}
        className="marker-container__marker-image"
        alt="marker"
      />
    </div>
  );
};

export default Marker;
