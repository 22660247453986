import "../scss/main.scss";
import Button from "./Button";
import { SiOpenstreetmap } from "react-icons/si";
import moment from "moment";
require("moment/locale/de.js");

const getNextTimeOpen = () => {};

const getNextTimeCloses = (openingHours) => {
  let day = moment().format("dddd");
  // return openingHours[day][0]?.end;
};

const PartnerItem = ({
  partner,
  onClick,
  className,
  id,
  backgroundColor,
  secondaryColor,
  primaryColor,
  hideMoreInfo,
  companyId,
  showMapIcon,
}) => {
  getNextTimeCloses();
  return (
    <div
      id={`${id}`}
      {...{ className: "partner-item-container " + className }}
      onClick={onClick}
      style={{ backgroundColor: backgroundColor, borderColor: secondaryColor }}
    >
      <img
        src={`data:image/jpg;charset=utf-8;base64,${partner?.logo}`}
        alt="partner-logo"
      />
      <div className="partner-item-container__partner-info">
        <span className="partner-item-container__partner-info__partner-name">
          {partner?.name}
        </span>
        <span className="partner-item-container__partner-info__partner-address">
          {partner?.adress}
        </span>
        <span className="partner-item-container__partner-info__partner-address">
          {partner?.plz} {partner?.city}
        </span>
      </div>
      <div className="partner-item-container__button-status-container">
        <span
          className={
            partner?.opening_hours.length === 0
              ? "opening-hours-regarding-info-text"
              : partner?.is_open
              ? "open-text"
              : "closed-text"
          }
        >
          {partner?.opening_hours.length === 0
            ? "Öffnungszeiten gemäss infos"
            : partner?.is_open
            ? "geöffnet"
            : "geschlossen"}
        </span>
        {/* {partner?.opening_hours.length !== 0 && (
          <span
            className="opening-hours-regarding-info-text"
            style={{ color: "white" }}
          >
            {partner?.is_open
              ? `closes at `
              : partner?.opening_hours?.Montag &&
                `opens at ${partner?.opening_hours?.Montag[0]?.start}`}
          </span>
        )} */}
        {!hideMoreInfo && (
          <Button
            title="Mehr Infos"
            to={`/partner-details?partner_id=${partner?.id}&company_id=${companyId}`}
            color={primaryColor}
            borderColor={secondaryColor}
          />
        )}
        {showMapIcon && (
          <div
            style={{ pointer: "cursor" }}
            onClick={() => {
              window.open(
                "https://maps.google.com?q=" +
                  partner?.latitude +
                  "," +
                  partner?.longitude
              );
            }}
          >
            <SiOpenstreetmap color={secondaryColor} size={20} />
          </div>
        )}
      </div>
    </div>
  );
};
export default PartnerItem;
