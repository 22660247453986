import "../scss/main.scss";
import { Link } from "react-router-dom";
const Button = ({ title, to, color, borderColor }) => {
  return (
    <Link
      to={to}
      target="_blank"
      rel="noreferrer"
      style={{ backgroundColor: color, borderColor }}
      className="button-container"
    >
      {title}
    </Link>
  );
};

export default Button;
